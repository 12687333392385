:root {
    --accent-color: #3880ff;
    --accent-color-rgb: 56, 128, 255;
    --accent-color-100: #4d8dff;
    --accent-color-200: #639cff;
    --accent-color-300: #4d8dff;
    --accent-color-400: #7cabff;
    --accent-color-500: #97bdff;
    --accent-color-600: #b2ceff;
    --accent-color-700: #cddfff;
    --accent-color-800: #e3edff;
    --accent-color-900: #f0f6ff;
    --background: #fff;
    --code-background: #f5f7fa;
    --code-color: var(--text-color--dark);
    --code-font-family: "SFMono-Regular", "Roboto Mono", Consolas, "Liberation Mono", Menlo, Courier, monospace;
    --font-family: system-ui, sans-serif;
    --header-height: 56px;
    --heading-font-family: "Eina", var(--font-family);
    --line-rule-color: #edf2f6;
    --menu-width: 240px;
    --offset-background: #fdfdff;
    --offset-background--dark: #f7f7ff;
    --page-margin-left: var(--menu-width);
    --text-color: #303943; //done
    --text-color--darker: #24282e; //done
    --text-color--dark: #020814; //done
    --text-color--lighter: #abb2bf; //done
    --text-color--light: #73849a; //done
}

//ionic card component
/* we can use this mixin to add shadow on card with hover effect*/
@mixin card-shadow() {
    transition: box-shadow 0.15s ease 0s, transform 0.15s ease 0s, -webkit-box-shadow 0.15s ease 0s, -webkit-transform 0.15s ease 0s;

    box-shadow: rgba(2, 8, 20, 0.1) 0px 0.175em 0.5em, rgba(2, 8, 20, 0.08) 0px 0.085em 0.175em;

    &:hover {
        box-shadow: rgba(2, 8, 20, 0.1) 0px 0.35em 1.175em, rgba(2, 8, 20, 0.08) 0px 0.175em 0.5em;
        transform: translateY(-1px);
    }
}

@mixin ionic-component-card() {
    height: 100%;
    margin: 0;
    display: flex !important;
    flex-direction: column !important;
    cursor: pointer !important;
    @include card-shadow();

    ion-card-header {
        padding-bottom: 0;

        ion-avatar {
            height: 48px;
            width: 48px;
        }

        ion-item {
            --inner-padding-bottom: 0;
            --padding-bottom: 0;
        }

        ion-card-title {
            --color: #var(--text-color--dark);
            ;
            font-size: 16.8px;
            ;
        }
    }

    p {
        font-size: 12px;
        // letter-spacing: 1px;
        // color: #737373;//actual p color
        color: #73849a; //ionic color
        line-height: 1.6;
        // font-family: system-ui, sans-serif;
    }

    ion-card-content {
        flex: 1 1 auto;
        padding-top: 0;

    }
}

//-----------------
a {
    // color: var(--accent-color);
    font-weight: 400;
    text-decoration: none
}


h1,
h2,
h3,
h4,
h5,
h6 {
    color: var(--text-color--dark);
    letter-spacing: -.03em;
    line-height: 1.1;
    padding-top: 2rem;
    font-weight: 700;
}

h1 {
    font-size: 3.5rem;
    padding-top: 0;
}

@media (max-width: 768px) {
    h1 {
        font-size: 3rem
    }
}

h2 {
    font-size: 1.825rem
}

h2,
h3 {
    margin-bottom: .2em
}

h3 {
    font-size: 1.375rem
}

h4 {
    font-size: 1.15rem
}

h5,
h6 {
    font-size: 1rem
}

h2+h3,
h3+h4 {
    margin-top: -1em
}

h2+p>small,
h3+p>small,
h4+p>small {
    display: block;
    margin-top: -2em
}

code,
kbd {
    color: var(--code-color);
    font-family: var(--code-font-family);
    font-size: 15px;
    font-weight: 500;
    letter-spacing: 0;
    padding: 4px 6px;
    white-space: pre-wrap;
}

code,
kbd,
pre {
    background-color: var(--code-background);
    border-radius: 4px
}

pre {
    line-height: 1.2;
    margin: 2rem 0;
    overflow: auto;
    padding: 2em
}

pre code {
    background-color: transparent;
    color: inherit;
    font-size: 13px;
    padding: 0;
    white-space: inherit
}