#note-card-row {
    ion-col {
        padding: 0;
    }
}

//to remove card shadow or radius set below 
// border-radius: 0;
// box-shadow: none;
ion-card {

    &.note-card,
    &.note-with-title-card {

        margin-left: 0;
        margin-right: 0;
        box-shadow: none;

        ion-card-header {
            padding: .5rem;

            ion-card-title {
                font-size: 1.2rem;
            }
        }

        ion-card-content {
            // font-family: Cambria, Cochin, Georgia, Times, 'Times New Roman', serif;
            background-color: rgba(25, 118, 210, 0.05);
            color: var(--text-color);
            font-size: 16px;

            b {
                color: var(--ion-text-color, #000000);
            }

        }
    }

    &.note-card {
        border-left: .5rem solid var(--ion-color-primary);
        border-bottom-left-radius: 0;
        border-top-left-radius: 0;

    }

    &.note-with-title-card {
        ion-card-content {
            padding-top: .5rem;
        }
    }
}