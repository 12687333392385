/* -----------------------------------------------------------------------------
 This file contains all application-wide Sass mixins.
 https://www.w3schools.com/css/css_rwd_mediaqueries.asp

 My Preference : Desktop First 
 ----------------------------------------------------------------------------- 
*/

/// Breakpoints 
$fa-xs:0px;
$fa-sm:576px;
$fa-md:768px;
$fa-lg:992px;
$fa-xl:1200px;
$fa-xxl: 1400px; // not part of ionic

$diff: .02px;

///breakpoints for each resolution pulled from variables fa-xs,fa-sm,fa-md and fa-lg
///media queries named as fa-{device}


/*********************************
MOBILE FIRST DESIGN
**********************************/

// X-Small devices (portrait phones, less than 576px)
// No media query for `xs` since this is the default in Bootstrap

// tablets, landscape phones, ≥576px
@mixin fa-tablet-up {
    @media (min-width: #{$fa-sm}) {
        @content;
    }
}

// large tablets, landscape tablets, ≥768px
@mixin fa-ltablet-up {
    @media (min-width: #{$fa-md}) {
        @content;
    }
}

// laptops/desktops, ≥992px
@mixin fa-desktop-up {
    @media (min-width: #{$fa-lg}) {
        @content;
    }
}

/// Large Desktops and laptops, ≥1200px
@mixin fa-ldesktop-up {
    @media (min-width : #{$fa-xl}) {
        @content;
    }
}

/// Extra Large Desktops and laptops, ≥1400px
@mixin fa-xldesktop-up {
    @media (min-width : #{$fa-xxl}) {
        @content;
    }
}


/*********************************
DESKTOP FIRST DESIGN
**********************************/

// X-Small devices (portrait phones, less than 576px)
@mixin fa-mobile {
    @media (max-width: #{$fa-sm - $diff}) {
        @content;
    }
}

// Small devices (landscape phones, tablets, less than 768px)
@mixin fa-tablet-down {
    @media (max-width: #{$fa-md - $diff}) {
        @content;
    }
}

// Medium devices (tablets, less than 992px)
@mixin fa-ltablet-down {
    @media (max-width: #{$fa-lg - $diff}) {
        @content;
    }
}

// Large devices (desktops, less than 1200px)
@mixin fa-desktop-down {
    @media (max-width: #{$fa-xl - $diff}) {
        @content;
    }
}

// X-Large devices (large desktops, less than 1400px)
@mixin fa-ldesktop-down {
    @media (max-width: #{$fa-xxl - $diff}) {
        @content;
    }
}

// XX-Large devices ,Extra Large Desktops and laptops, <1400px
// No media query since the xxl breakpoint has no upper bound on its width

// ************** VIEWPORT MEDIA QUERY END HERE ************* //

// Utility for break-points.
@mixin fa-breakpoints($minpoint, $maxpoint) {
    @media (min-width: $minpoint) and (max-width: $maxpoint) {
        @content;
    }
}

//we can register more app-level specific mixins below. Below is one example


/*********************************
VIDEO SPECIFIC MIXINS
**********************************/
@mixin videoMin {
    @media (min-width: 1075px) {
        @content;
    }
}

@mixin videoMax {
    @media (max-width: 1074px) {
        @content;
    }
}