@import './../../../theme/cards';

#quiz-detail {
    ion-toolbar {
        // position: absolute;

        top: 0;
        left: 0;
        right: 0;

        --background: transparent;
        // --color: white;
    }

    /* ion-toolbar ion-button,
ion-toolbar ion-back-button,
ion-toolbar ion-menu-button {
    --color: white;
} */
    .quiz-page-row--first {
        height: 80vh;
    }

    .result--first-row {
        min-height: 150px;
    }

    .grid-full-width {
        ion-row.result--first-row {

            h1,
            h2,
            h3,
            p {
                color: #ffffff;
            }
        }
    }

}