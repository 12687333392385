@import './../../theme/ionic';
@import '../../theme/mixins';


#home-page {
    .first-row {
        margin-top: 5rem;
    }

    h2 {
        font-weight: 600;
        font-size: 48px;
        line-height: 110%;
        letter-spacing: -.05em;
        color: #020814;
        padding-top: 0;
    }

    h1 {
        font-size: 4em;
        line-height: 100%;
        letter-spacing: -.04em;
        color: #020814;
        font-weight: 700;
        -webkit-margin-after: 24px;
        margin-block-end: 24px;

        @include fa-desktop-down {
            font-size: 3em;
        }

        @include fa-tablet-down {
            font-size: 2em;
        }
    }

    h3 {
        font-weight: 600;
        font-size: 36px;
        line-height: 110%;
        letter-spacing: -.05em;
        color: #020814;
        padding-top: 0;
    }

    p {
        color: var(--text-color);
        font-size: 20px;
        margin-bottom: 1.2rem;
        line-height: 160%;
        letter-spacing: -.02em;
        margin-block-end: 32px;
    }

    p.intro {
        color: var(--text-color--light);
        font-size: 22px;
        margin-bottom: 1.2rem;
        line-height: 160%;
        letter-spacing: -.02em;
        margin-block-end: 32px;

        .darken {
            color: #001a3a;
            font-weight: 500
        }
    }


    section {
        padding-top: 170px;
        line-height: 1.75em;
        padding-bottom: 150px;

    }

    .plug-image-grid {
        max-width: 100vw;
        padding: 0;

        #plug-img {
            background-color: #05467e;
            text-align: center;

            img {
                max-width: 450px;
            }
        }

        #plug-desc {
            padding: 48px;
            background-color: #2dccd3;

            p {
                padding-top: 1rem;
            }
        }
    }

    .center-text-row {
        padding: 20px;
        min-width: 300px;
        background-color: #edeeee;
        margin-top: auto;
        margin-bottom: auto;

        h2 {
            padding-top: 4rem;
            padding-bottom: 3rem;
            letter-spacing: .05em;
        }
    }



    .fa-footer {
        background-color: #000;
        color: #fff;
        min-height: 200px;
        margin-top: 4rem;
        padding-bottom: 2rem;

        p,
        h1,
        h2,
        h3,
        h4 {
            color: #fff;
        }

        .container {
            max-width: 1200px;
            padding-top: 2rem;

            .logo {
                img {
                    height: 45px;
                }
            }

            .copyright {
                h4 {
                    color: rgba(255, 255, 255, 0.6);
                    font-size: 15px;
                    line-height: 20px;
                    font-weight: 300;
                }
            }

            a {
                text-decoration: none;

                h4 {
                    color: rgba(255, 255, 255, 0.95);
                    line-height: 24px;
                    font-size: 18px;
                    font-weight: 500;
                    letter-spacing: .01em;
                }
            }

            hr {
                border-top: 1px solid #d5d5d5;
                width: 100%;
                margin-top: .8rem;
                margin-top: 1rem;
            }
        }

    }

}