// Ionic components customize

// apply this class to loader to see nice black background colored loader.
.my-custom-loader {
    --background: #222;
    --spinner-color: #fff;
    color: #fff;
    --min-width: 310px;
}

// Custom alert class with black background.
.my-custom-alert {
    .alert-wrapper {
        --background: #222;

        div {
            color: #fff;
        }
    }

    &.error {
        .alert-wrapper {
            .alert-title {
                color: var(--ion-color-danger, #ff4961);
            }
        }
    }
}

ion-split-pane {
    --side-width: 300px;
    --side-max-width: 350px;
  }

// applying this class makes the grid width to 100vw
.grid-full-width {
    max-width: 100vw;
    padding: 0;
}
ion-toolbar {
    // --background: linear-gradient(270deg, #fff1eb, #fff1eb 2%, #d6e8ff);
}