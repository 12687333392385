@import './../../theme/ionic';

#tag-list-page {
    .first-row {
        margin-top: 3rem;
        margin-bottom: 5rem;
    }
}

ion-grid {
    max-width: 1100px;
}

.ionic-component-card {
    @include ionic-component-card()
}